@import "abstracts/vars";

main #content {
	.rich-text.remove-cta-list-style {
		ul, li {
			list-style: disc; }
		li {
			display: list-item;
			margin-left: 1.5rem; }
		a {
			background-color: initial;
			text-decoration: underline;
			color: $colorBrandPrimary;
			font-size: initial;
			padding: initial;
			display: initial;
			font-weight: initial; } } }

main #content {
	.rich-text.format-list-blue-title-grey-back {
		padding-right: 40px;
		.component-content {
			padding: 0;
			background:#dbdbdb {}
			font-family: 'Gotham SSm A', 'Gotham SSm B', san-serif;
			font-size: .88 em;
			color:#000000 {}
			margin: 0 0 1.5em 0;
			padding: 0 0 5px;
			h3 {
				padding: 15px;
				background: #297CBB;
				color: white;
				margin: 0 0 0 0;
				border-bottom: 2px solid #febe10; }
			p {
				padding: 15px 15px 0;
				line-height: 1.5; }
			ul {
				padding: 15px 15px 15px; } } } }
